




































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { MapSupportingItem } from '@/entities/MapSupportingItem';

@Component
export default class MapPicker extends Vue {
  @Prop()
  private selectedItem?: MapSupportingItem;
  @Prop() private showHeading?: boolean;
  @Prop() private HideMap?: boolean;
  @Prop() private IsReadOnly?: boolean;
  @Prop() private IsStreetAddressRequired?: boolean;
  @Prop() private IsCityRequired?: boolean;
  private IsShowMap = false;
  private localHideMap = false;
  private mounted() {
    this.localHideMap = this.HideMap !== null && this.HideMap !== undefined ? this.HideMap : false;
    this.renderInitialMap();
    this.showMap();
  }

  private get displayMap(): boolean {
    if (this.selectedItem) {
      if (this.selectedItem.Longitude !== 0 && this.selectedItem.Latitude !== 0) {
        return true;
      }
    }
    return true;
  }
  private set displayMap(val: boolean) {
    if (val === false) {
      if (this.selectedItem) {
        this.selectedItem.Latitude = 0;
        this.selectedItem.Longitude = 0;
      }
      this.IsShowMap = false;
    }
  }
  private displayMapClick(): any {
    if ((this.$refs.displayMapCheckbox as HTMLInputElement).checked) {
      if (this.selectedItem) {
        const geocoder = new google.maps.Geocoder();
        const geocodeRequest = { address: this.selectedItem.Address + ' ' +  this.selectedItem.City };
        geocoder.geocode(geocodeRequest, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            if (this.selectedItem) {
              this.IsShowMap = true;
              this.selectedItem.Latitude = results[0].geometry.location.lat();
              this.selectedItem.Longitude = results[0].geometry.location.lng();
              this.renderMap();
            }
          } else {
            this.IsShowMap = false;
            // Do we want to present error somehow?
          }
        });
      }
    }
  }
  private renderMap() {
    if (this.selectedItem) {
      const mapProperties = {
        center: new google.maps.LatLng(this.selectedItem.Latitude, this.selectedItem.Longitude),
        zoom: 13,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        fullscreenControl: false,
      };
      const mapInst = new google.maps.Map(this.$refs.mapcontainer as HTMLElement, mapProperties);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(this.selectedItem.Latitude, this.selectedItem.Longitude),
        map: mapInst,
      });
    }
  }
  private renderInitialMap() {
    if (this.selectedItem) {
      if (this.selectedItem.Latitude !== 0 && this.selectedItem.Longitude !== 0) {
        this.renderMap();
      }
    }
  }

  private showMap() {
    if (this.selectedItem) {
      if (this.selectedItem.Latitude !== 0 && this.selectedItem.Longitude !== 0) {
        this.IsShowMap = true;
        this.displayMap= true;
      } else {
        this.displayMap= false;
        this.IsShowMap = false;
      }
    }
  }

  @Watch('selectedItem')
  private onPropertyChanged() {
    this.renderInitialMap();
    this.showMap();
  }
}
