import { Vue } from 'vue-property-decorator';

export default class DialogueThemesSettings extends Vue{
  // this is dialogues default setting for all
  public static PreviewChartsData  = [10, 20, 30, 25, 15, 10] ;
  public static DefaultCustomizedThemeCounts = 8;
  public static DefaultLogoSize = 2;
  public static DefaultLogoHeight = 60;
  public static DefaultBackgroundImageHeight = 900;
  public static DefaultBackgroundImageWidth = 1600;
  public static PieChart = 'pie';
  public static ColumnChart = 'column';
  public static ChartColors = ['#295E48','#86198F','#E49736','#C260B9','#1E3A8A','#6396DD'];
  public static FontColor = '#333333';
  public static BackgroundColor = '#FFFFFF';
  public static ChartTitle = 'Question Title';
  public static ThemeColor: any[] = [{Id: 0,Color:'#295E48',ThemeId:0,IsDeleted: false},{Id: 0,Color:'#86198F',ThemeId: 0,IsDeleted: false},{Id: 0,Color:'#E49736',ThemeId:0,IsDeleted: false},{Id: 0,Color:'#C260B9',ThemeId:0,IsDeleted: false},{Id: 0,Color:'#1E3A8A',ThemeId:0,IsDeleted: false},{Id: 0,Color:'#6396DD',ThemeId:0,IsDeleted: false}];
}
