




































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import VueDraggableResizable from 'vue-draggable-resizable';
@Component({
  components: {
    VueDraggableResizable,
  },
})
export default class FeaturedImage extends Vue {
  @Prop() private imgSrc?: string;
  @Prop() private imageWidth?: number;
  @Prop() private imageHeight?: number;
  @Prop() private leftPostion?: string;
  @Prop() private topPosition?: string;
  @Prop() private moduleName?: string;
  private loadingImage: string = require('@/assets/Images/loading.png');
  private featureMissingImage: string = require('@/assets/Images/ImageMissingFeature.png');
  private img: string | undefined = require('@/assets/Images/ImageMissing.png');
  private showImageScaleandCrop = false;
  private x = 0;
  private y = 0;
  private pictureContainerWidth = 0;
  private pictureContainerHeight = 0;
  private headerImageWidth = 0;
  private headerImageHeight = 0;
  private localImageWidth = 0;
  private localImageHeight = 0;
  // constants
  private readonly defaultImageWidth = 320;
  private readonly defaultImageHeight = 200;
  // update x and y positon of draggable component
  public getXY(): string {
    if (this.y >= 0) {
      this.y = 0;

    } else if (this.y <= this.pictureContainerHeight - this.headerImageHeight && this.pictureContainerHeight !== 0 && this.headerImageHeight !== 0) {
      this.y = this.pictureContainerHeight - this.headerImageHeight;
    }
    // Moving left and right
    if (this.x >= 0) {
      this.x = 0;

    } else if (this.x <= this.pictureContainerWidth - this.headerImageWidth && this.pictureContainerWidth !== 0 && this.headerImageWidth !== 0) {
      this.x = this.pictureContainerWidth - this.headerImageWidth;
    }
    let result = '';
    result = 'top:' + this.y + 'px;left:' + this.x + 'px;';
    return result;
  }
  // get image dimentions respective to image height and width
  public getImageDimensions(): string {
    let selectedHeight = this.localImageHeight;
    let selectedWidth = this.localImageWidth;
    if (this.localImageHeight === 0 || this.localImageWidth === 0) {
      selectedHeight = this.imageHeight!;
      selectedWidth = this.imageWidth!;
    }
    if (this.localImageHeight >= 400) {
      selectedHeight = this.localImageHeight / 2;
    }
    if (this.localImageWidth >= 640) {
      selectedWidth = this.localImageWidth / 2;
    }
    let result = '';
    result = 'height:' + selectedHeight + 'px;width:' + selectedWidth + 'px;';
    return result;
  }
  // update thumbnail image on image drag stop.
  public onDragstop() {
    // if (!this.showImageScaleandCrop) { return; }

    if (this.img) {
      if (
        !this.img.includes('/img/loading') &&
        !this.img.includes('data:image/png')
      ) {
        this.$emit(
          'CroppedFeaturedImageIconUrl',
          this.loadingImage,
          this.loadingImage,
        );
        this.UploadFileImage(
          this.img,
          this.y.toString(), // top
          this.x.toString(), // left
          this.localImageWidth.toString(),
          this.localImageHeight.toString(),
        );
      } else {
        this.img = require('@/assets/Images/ImageMissing.png');
      }
    }
  }
  // function called when dom is mounted
  private mounted() {
    this.updateLocalProperties();
  }
  // function called when dom is updated
  private updated() {
    if (this.img === this.featureMissingImage) {
      this.localImageWidth = this.defaultImageWidth;
      this.localImageHeight = this.defaultImageHeight;
      this.getImageDimensions();
    }
  }
  // function called whenever imagewidth wll change
  @Watch('imageWidth')
  private onImageWidthChanged() {
    this.updateLocalProperties();
  }
  // function called whenever imageHeight will change.
  @Watch('imageHeight')
  private onImageHeightChanged() {
    this.updateLocalProperties();
  }
  // function called whenever image src will change
  @Watch('imgSrc')
  private onImgSrcUpdated(val: string) {
    if (val === this.featureMissingImage) {
      this.localImageWidth = this.defaultImageWidth;
      this.localImageHeight = this.defaultImageHeight;
      this.getImageDimensions();
    } else if (val !== this.loadingImage) {
      this.img = val;
      this.onDragstop();
    }
  }
  // common function to update the local properties
  private updateLocalProperties() {
    this.localImageWidth = this.imageWidth!;
    this.localImageHeight = this.imageHeight!;
    if (this.leftPostion && this.topPosition) {
      this.x = parseFloat(this.leftPostion);
      this.y = parseFloat(this.topPosition);
    }
  }
  // get x and y position of image on image drag
  private onDrag(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
  // show or hide featured image crop section
  private ShowDivImageScaleandCrop() {
    if (this.showImageScaleandCrop) {
      this.$emit('CroppedImageIconUrl', '', '');
    }
    this.showImageScaleandCrop = !this.showImageScaleandCrop;
  }
  // get image url and update local image properties.
  private GetImgeUrl() {
    this.img = this.imgSrc;
    return this.img;
  }
  // get image position respective to dragging control.
  private getImagePositions() {
    if (this.$el.querySelector('.picturecontainer')) {
      this.pictureContainerHeight = this.$el.querySelector('.picturecontainer')!.clientHeight;
      this.headerImageHeight = this.$el.querySelector('.headerimage')!.clientHeight;
      this.pictureContainerWidth = this.$el.querySelector('.picturecontainer')!.clientWidth;
      this.headerImageWidth = this.$el.querySelector('.headerimage')!.clientWidth;
      this.localImageWidth = this.headerImageWidth;
      this.localImageHeight = this.headerImageHeight;
    }
  }
  // call api to get cropped thumbnail image
  private UploadFileImage(
    url: string | undefined = '',
    topPosition: string,
    leftPosition: string,
    w: string,
    h: string,
  ) {
    const formData = new FormData();
    formData.append('url', url);
    formData.append('module', this.moduleName!);
    formData.append('topPosition', topPosition);
    formData.append('leftPosition', leftPosition);
    this.$axios
      .post('/FileUpload/GenerateFeaturedImageThumbnail', formData)
      .then((response) => {
        {
          this.$emit(
            'CroppedFeaturedImageIconUrl',
            response.data.thumbUri,
            response.data.fullUri,
            topPosition,
            leftPosition,
          );
          this.$parent.$emit(
            'SetFeaturedImageIconUrl',
            response.data.thumbUri,
            response.data.fullUri,
            h,
            w,
            topPosition,
            leftPosition,
          );
        }
      })
      .catch(() => {
        // Log error if required
      });
  }
}
