




























































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import colorpicker from '@/components/Common/colorpicker.vue';
import vuecroppie from '@/components/Common/ImageCroppie.vue';
import loading from '@/components/Common/loading.vue';
import featuredImage from '@/components/Common/FeaturedImage.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import DialogueThemesSettings from '@/helper/DialogueThemesSettings';
import {
  FontAwesomeViewModel,
  FontAwesomeIcon,
} from '@/entities/FontAwesomeViewModel';
import { StoreHelper } from '@/store/StoreHelper';
import { AppColorSettingsInfo } from '@/entities/Application/AppColorSettingsInfo';
import i18n from '@/i18n';
import { VueModalPopUpViewModel } from '@/entities/VueModalPopUpViewModel';
import VueModalPopUp from '@/components/Common/VueModalPopUp.vue';
import PrefixMediaUpload from '@/entities/MediaPrefix';

@Component({
  components: {
    colorpicker,
    vuecroppie,
    loading,
    featuredImage,
    ModalPopup,
    ValidationSummary,
    VueModalPopUp,
  },
})
export default class ImageProcess extends Vue {
  @Prop() private imageSrc?: string;
  @Prop() private imageFullSrc?: string;
  @Prop() private featuredimageSrc?: string;
  @Prop() private featuredImageFullSrc?: string;
  @Prop() private ShowFeaturedImage?: boolean;
  @Prop() private HeadingTitle?: string;
  @Prop() private HasFeaturedImage?: boolean;
  @Prop() private moduleName?: string;
  @Prop() private applicationborderColor?: string;
  @Prop() private featuredImageWidth?: number;
  @Prop() private featuredImageHeight?: number;
  @Prop() private leftPosition?: string;
  @Prop() private topPosition?: string;
  @Prop() private isBulkOperation?: boolean;
  @Prop() private oldSelectedFontAwesomeIcon?: FontAwesomeIcon;
  @Prop() private OnlyShowFeatureImage?: boolean;
  @Prop() private showTitleBelowFeaturedImage?: boolean;
  @Prop() private SubModuleName?: string;
  @Prop() private IsFullImage?: boolean;
  @Prop() private ShowFullImageOption?: boolean;
  // batch operation
  private bulkOperationJsonData = '';
  private selectedBatchImageRadiobtn = 'both';
  // modal popup
  private modalPopupContent = '';
  private modalBodyContent = '';
  private modalPopupVisible = false;
  private showCloseButton = true;
  // validation summary
  private visibleValidationSummary = false;
  private validationErrorFields: string[] = [];
  private isMobileView = false;
  private TypeofImageIconImage = 'IconImage';
  private TypeofImageIconFeatureImage = 'CroppedFeaturedImageIconUrl';
  private defaultColor = '#FF0000';
  private imageIconUrl = '';
  private croppedimageIconUrl = '';
  private croppedFeaturedImageIconUrl = '';
  private featuredImageUrl = '';
  private uploadImage = 'file';
  private imageFileFeatureImage = 'file';
  private errorText = '';
  private featuredImageerrorText = '';
  private maxSize = 1;
  private featuredImageSize = 1;
  private featuredImageSizeWidth = 640;
  private featuredImageSizeHeight = 400;
  private maxBackgroundImageSize = 5;
  private backgroundImageWidth = 1600;
  private backgroundImageHeight = 900;
  private backgroundImageHeight700 = 700;
  private backgroundImageWidthRatio = 16;
  private backgroundImageHeightRatio = 9;
  private backgroundImageHeightRatio7 = 7;
  private featuredImageTopPosition = '';
  private featuredImageLeftPosition = '';
  private IconImageSizeWidth = 150;
  private IconImageSizeHeight = 150;
  private uploadFieldName = 'file';
  private optionGenereateIconSelected = 'false';
  private optionFeatureImagedefaultColorSelected = 'false';
  private ProgramFontAwesomeViewModel: FontAwesomeViewModel =
    FontAwesomeViewModel.createEmpty();
  private color = '#FF00FF';
  private LatestForegroundcolor = '#FFFFFF';
  private LatestBackroundcolor = '#FFFFFF';
  // flag to check if color is changed
  private updateColorInDB = false;
  private loadingImage: string = require('@/assets/Images/loading.png');
  private backIconFile: string = require('@/assets/Images/Back-arrow.svg');
  private Iconfilename = i18n
    .t('VirtualMeetings.AddVideo.ChooseFile')
    .toString();
  private FeaturedIconfilename = i18n
    .t('VirtualMeetings.AddVideo.ChooseFile')
    .toString();
  private localFeaturedImageWidth = 0;
  private localFeaturedImageHeight = 0;
  private IconGeneratorId = 0;
  private LatestFontAwesomeIcon: FontAwesomeIcon =
    FontAwesomeIcon.createEmpty();
  private visibleDeleteLinkUnderImage = true;
  private visibleDeleteLinkUnderFeaturedImage = true;
  private localShowTitleBelowFeaturedImage = false;
  private isVisibleShowTitleCheckBox = false;
  private isSaveButtonDisabled = false;
  private localIsFullImage = false;
  private localShowFullImageOption = false;
  private storeHelper: StoreHelper = new StoreHelper(this.$store);
  // Loader variables
  private showLoading = false;
  // constants
  private readonly EditBoth = 'both';
  private readonly EditIcon = 'icon';
  private readonly EditFeatured = 'featured';
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private featureMissingImage: string = require('@/assets/Images/ImageMissingFeature.png');
  private readonly defaultForegroundColor = '#ffffff';
  private readonly defaultBackgroundColor = '#4c7e90';
  private readonly CustomCategoryModule = 'CustomCategory';
  private readonly ProgramModule = 'Program';
  private readonly EventModule = 'Event';
  private readonly CustomTheme = 'CustomTheme';
  private readonly EventWebPage = 'EventWebPage';
  private localOnlyShowFeatureImage = true;
  private mediaPrefix = '';
  // private localImageSrc = '';
  public loaderBorderColor = '';

  // Old Value variables
  private oldImageSrc = '';
  private oldImageFullSrc = '';
  private oldFeaturedimageSrc = '';
  private oldFeaturedImageFullSrc = '';
  private oldApplicationborderColor = '';
  private oldFeaturedImageWidth = 0;
  private oldFeaturedImageHeight = 0;
  private oldLeftPosition = '';
  private oldTopPosition = '';
  private oldCroppedFeaturedImageIconUrl = '';
  private defaultCustomThemeLogoSize = DialogueThemesSettings.DefaultLogoSize;

  private isDirtyObject = true;
  private showVueModalPopUp = false;
  private unsavedModalPopUp: VueModalPopUpViewModel = VueModalPopUpViewModel.createEmpty();
  // Batch Operations

  // Validation summary
  private CloseValidationSummary(visible: boolean) {
    this.visibleValidationSummary = visible;
  }
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      this.SaveImagesInBatch();
    }
  }
  private ShowExternalInfoFeaturedImageMessage(): boolean {
    return this.moduleName === this.CustomCategoryModule &&
      this.isBulkOperation === true
      ? true
      : false;
  }
  private GetShowFeaturedImageText(): string {
    return this.moduleName === this.CustomCategoryModule
      ? this.$t('ImageProcess.ShowInformationtitle').toString()
      : this.$t('ImageProcess.ShowProgramtitle').toString();
  }
  private SaveImagesInBatch() {
    if (this.optionFeatureImagedefaultColorSelected === 'true') {
      this.croppedFeaturedImageIconUrl = '';
    }
    if (!this.localShowTitleBelowFeaturedImage) {
      this.localShowTitleBelowFeaturedImage = false;
    }
    if (this.selectedBatchImageRadiobtn === this.EditBoth) {
      this.bulkOperationJsonData = JSON.stringify({
        selectedBatch: this.EditBoth,
        imageIconUrl: this.imageIconUrl,
        croppedimageIconUrl: this.croppedimageIconUrl,
        featuredImageUrl: this.featuredImageUrl,
        croppedFeaturedImageIconUrl: this.croppedFeaturedImageIconUrl,
        topPosition: this.featuredImageTopPosition,
        leftPosition: this.featuredImageLeftPosition,
        showFeaturedImageBelowTitle: this.localShowTitleBelowFeaturedImage,
        featuredImageWidth: this.localFeaturedImageWidth,
        featuredImageHeight: this.localFeaturedImageHeight,
      });
    } else if (this.selectedBatchImageRadiobtn === this.EditIcon) {
      this.bulkOperationJsonData = JSON.stringify({
        selectedBatch: this.EditIcon,
        imageIconUrl: this.imageIconUrl,
        croppedimageIconUrl: this.croppedimageIconUrl,
        showFeaturedImageBelowTitle: this.localShowTitleBelowFeaturedImage,
      });
    } else if (this.selectedBatchImageRadiobtn === this.EditFeatured) {
      this.bulkOperationJsonData = JSON.stringify({
        selectedBatch: this.EditFeatured,
        featuredImageUrl: this.featuredImageUrl,
        croppedFeaturedImageIconUrl: this.croppedFeaturedImageIconUrl,
        topPosition: this.featuredImageTopPosition,
        leftPosition: this.featuredImageLeftPosition,
        showFeaturedImageBelowTitle: this.localShowTitleBelowFeaturedImage,
        featuredImageWidth: this.localFeaturedImageWidth,
        featuredImageHeight: this.localFeaturedImageHeight,
      });
    }
    if (this.updateColorInDB) {
      this.UpdateIconColors();
    }
    this.$emit('update-bulk-images', this.bulkOperationJsonData);
  }
  private SetOptionGenereateIconSelected() {
    this.optionGenereateIconSelected = 'false';
  }
  private CheckForImageUpload(): boolean {
    if (
      this.selectedBatchImageRadiobtn === this.EditBoth &&
      (this.imageIconUrl === this.missingImage ||
        this.imageIconUrl === '' ||
        ((this.featuredImageUrl === this.featureMissingImage ||
          this.featuredImageUrl === '') &&
          this.optionFeatureImagedefaultColorSelected === 'false'))
    ) {
      return true;
    } else if (
      this.selectedBatchImageRadiobtn === this.EditIcon &&
      (this.imageIconUrl === this.missingImage || this.imageIconUrl === '')
    ) {
      return true;
    } else if (
      this.selectedBatchImageRadiobtn === this.EditFeatured &&
      (this.featuredImageUrl === this.missingImage ||
        this.featuredImageUrl === '') &&
      this.optionFeatureImagedefaultColorSelected === 'false'
    ) {
      return true;
    } else {
      return false;
    }
  }

  private Save() {
    if (this.isBulkOperation) {
      if (this.CheckForImageUpload()) {
        this.validationErrorFields = [];
        this.validationErrorFields.push(
          this.$t('PleaseSelectImage').toString(),
        );
        this.visibleValidationSummary = true;
        return false;
      } else {
        this.modalPopupContent = this.$t('EditImage.Bulk.Warning').toString();
        this.modalPopupVisible = true;
      }
    } else {
      this.clickOnBack();
    }
  }

  private SetForegroundcolor(color: string, oldColor: string) {
    // old color will be empty if component first time loaded
    if (oldColor !== '') {
      this.updateColorInDB = true;
    }
    // for jira 4909 (.trim)
    this.LatestForegroundcolor = color.trim();
    this.ClickIconGenerator(this.LatestFontAwesomeIcon);
  }
  private SetBackgroundcolor(color: string, oldColor: string) {
    // old color will be empty if component first time loaded
    if (oldColor !== '') {
      this.updateColorInDB = true;
    }
    // for jira 4909 (.trim)
    this.LatestBackroundcolor = color.trim();
    this.ClickIconGenerator(this.LatestFontAwesomeIcon);
  }
  private SwapColors() {
    this.updateColorInDB = true;
    const tempColor = this.LatestForegroundcolor;
    this.LatestForegroundcolor = this.LatestBackroundcolor;
    this.LatestBackroundcolor = tempColor;
  }
  private ResetColorToDefault() {
    this.updateColorInDB = true;
    const appDefaultColorsInfo: AppColorSettingsInfo =
      this.storeHelper.GetApplicationColorSettingsFromStore();
    this.LatestForegroundcolor = appDefaultColorsInfo.topNavForegroundColor;
    this.LatestBackroundcolor = appDefaultColorsInfo.topNavBackgroundColor;
    if (this.LatestForegroundcolor === null) {
      this.LatestForegroundcolor = this.defaultForegroundColor;
    }
    if (this.LatestBackroundcolor === null) {
      this.LatestBackroundcolor = this.defaultBackgroundColor;
    }
    if (this.LatestFontAwesomeIcon.Id === 0) {
      this.LatestFontAwesomeIcon = this.oldSelectedFontAwesomeIcon!;
    }
    this.ClickIconGenerator(this.LatestFontAwesomeIcon);
  }
  private GetForegroundcolor() {
    return {
      background: this.LatestForegroundcolor,
      border: '4px solid #bbb',
    };
  }
  private GetBackgroundcolor() {
    return {
      background: this.LatestBackroundcolor,
      border: '4px solid #bbb',
    };
  }
  private GetFontAwesomeIconIconNameClass() {
    return {
      'font-size': 'xx-large',
      'cursor': 'pointer',
      'font-weight': 'bold',
      'margin': '5px 10px',
    };
  }
  private onImageIconFileChange(fieldName: string, file: any) {
    this.imageIconUrl = '';
    const { maxSize } = this;
    const imageFile = file[0];
    if (file.length > 0) {
      this.errorText = '';
      const size = imageFile.size / (maxSize * 1000) / (maxSize * 1000);
      if (
        !imageFile.type.match('image/png') &&
        !imageFile.type.match('image/x-png') &&
        !imageFile.type.match('image/jpg') &&
        !imageFile.type.match('image/jpeg')
      ) {
        this.errorText = 'Image.Upload.ErrorChooseImageOnly';
      } else if (size > 1 && !this.localIsFullImage) {
        this.errorText = 'Image.Upload.ErrorImageSize';
      } else if (size > this.defaultCustomThemeLogoSize && this.localIsFullImage) {
        this.errorText = 'Image.Upload.ErrorImageSize';
      } else {
        this.isDirtyObject = false;
        this.Iconfilename = file[0].name;
        const url = URL.createObjectURL(imageFile);
        this.ValidateImageFileSize(
          imageFile,
          url,
          this.imageIconUrl,
          'lessthan',
          this.IconImageSizeWidth,
          this.IconImageSizeHeight,
          'iconImageUrl',
        );
      }
    }
  }
  private IsImageIconUrlExists() {
    let selectedImage: string | undefined = this.imageIconUrl;
    if (this.imageIconUrl === '') {
      selectedImage = this.imageFullSrc;
    }
    if (
      selectedImage === '' ||
      typeof selectedImage === 'undefined' ||
      selectedImage == null
    ) {
      return false;
    }
    if (
      !selectedImage.includes('data:image/png') &&
      !selectedImage.includes('/loading') &&
      !selectedImage.includes('ImageMissing.png') &&
      !selectedImage.includes('/img/icon-placeholder')
    ) {
      return true;
    } else {
      return false;
    }
  }
  private IsFeaturedImageIconUrlExists() {
    let selectedImage: string | undefined = this.featuredImageUrl;
    if (this.featuredImageUrl === '') {
      selectedImage = this.featuredImageFullSrc;
    }
    if (
      selectedImage === '' ||
      typeof selectedImage === 'undefined' ||
      selectedImage == null
    ) {
      this.isVisibleShowTitleCheckBox = false;
      return false;
    }
    if (!selectedImage.includes('data:image/png') && !selectedImage.includes('/img/icon-placeholder')) {
      this.isVisibleShowTitleCheckBox = true;
      return true;
    } else {
      this.isVisibleShowTitleCheckBox = false;
      return false;
    }
  }

  private GetImageIconUrl() {
    return this.imageIconUrl === '' ? this.imageFullSrc : this.imageIconUrl;
  }

  private GetCroppedImageIconUrl() {
    return this.croppedimageIconUrl === '' || this.croppedimageIconUrl === undefined
      ? this.imageSrc
      : this.croppedimageIconUrl;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private CroppedImageIconUrl(imgurl: string, mainMediaFullUrl: string) {
    if (imgurl !== undefined && imgurl !== '' && imgurl !== null) {
      // delete link should not be visible under image if image is not loaded
      if (imgurl.includes('/img/loading')) {
        this.visibleDeleteLinkUnderImage = false;
      } else {
        this.croppedimageIconUrl = imgurl;
        this.visibleDeleteLinkUnderImage = true;
      }
    } else if(mainMediaFullUrl !== null && mainMediaFullUrl !== undefined) {
      this.visibleDeleteLinkUnderImage = true;
    } else {
      this.visibleDeleteLinkUnderImage = false;
    }
  }
  private GetCroppedFeaturedImageIconUrl() {
    const result = this.croppedFeaturedImageIconUrl === ''
      ? this.featuredImageFullSrc
      : this.croppedFeaturedImageIconUrl;
    if(result !== null && result !== '' && result !== undefined && !result.includes('/img/icon-placeholder')) {
      return result;
    } else {
      return this.featureMissingImage;
    }
  }
  private CroppedFeaturedImageIconUrl(
    imgurl: string,
    mainMediaFullUrl: string,
    top: string,
    left: string,
  ) {
    if(imgurl !== '' && imgurl !== undefined){
      this.croppedFeaturedImageIconUrl = imgurl;
      this.featuredImageTopPosition = top;
      this.featuredImageLeftPosition = left;
      // delete link should not be visible under featured image if featured image is not loaded
      if (imgurl.includes('/img/loading')) {
        this.visibleDeleteLinkUnderFeaturedImage = false;
      } else {
        this.visibleDeleteLinkUnderFeaturedImage = true;
      }
    } else {
      this.visibleDeleteLinkUnderFeaturedImage = false;
    }
  }
  private GetFeaturedImageIconUrl() {
    return this.featuredImageUrl === ''
      ? this.featuredImageFullSrc
      : this.featuredImageUrl;
  }

  private clearImageIconUrl() {
    this.imageIconUrl = '';
    this.$emit('selectImageUrl', '');
    this.$emit('SetImageIconUrl', '', '');
    this.Iconfilename = 'Choose file...';
    // preview image should be deleted
    this.croppedimageIconUrl = '';
    this.isDirtyObject = false;
    // this.localImageSrc = '';
  }
  private onFeaturedImageIconFileChange(fieldName: string, file: any) {
    this.featuredImageUrl = '';
    this.featuredImageSize = this.moduleName === this.EventModule ? 2 : 1;
    const { featuredImageSize } = this;
    const imageFile = file[0];
    if (file.length > 0) {
      this.featuredImageerrorText = '';
      const size =
        imageFile.size /
        (featuredImageSize * 1000) /
        (featuredImageSize * 1000);
      if (
        !imageFile.type.match('image/png') &&
        !imageFile.type.match('image/x-png') &&
        !imageFile.type.match('image/jpg') &&
        !imageFile.type.match('image/jpeg')
      ) {
        this.featuredImageerrorText = 'Image.Upload.ErrorChooseImageOnly';
      } else if (size > 1 && !this.localIsFullImage) {
        this.featuredImageerrorText = 'Image.Upload.ErrorImageSize';
      } else if (size > 5 && this.localIsFullImage) {
        this.featuredImageerrorText = 'Image.Upload.ErrorImageSize';
      } else {
        this.isDirtyObject = false;
        this.FeaturedIconfilename = file[0].name;
        const url = URL.createObjectURL(imageFile);
        this.ValidateImageFileSize(
          imageFile,
          url,
          this.imageIconUrl,
          'lessthan',
          this.featuredImageSizeWidth,
          this.featuredImageSizeHeight,
          'featuredImageUrl',
        );
      }
    }
  }
  private ValidateImageFileSize(
    file: any,
    url: string,
    imageFile: string,
    operation: string,
    width: number,
    height: number,
    propertyName: string,
  ) {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      const tempWidth = img.width;
      const tempHeight = img.height;
      if(this.localIsFullImage && propertyName === 'iconImageUrl' ) {
        if(tempHeight < 60) {
          this.errorText = 'EventSetting.CustomTheme.UploadLogoErrorMessage';
        } else {
          this.imageIconUrl = this.loadingImage;
          this.croppedimageIconUrl = this.loadingImage;
          this.UploadFileImage(
            '/FileUpload/UploadMediaDuringEditJson',
            file,
            tempWidth.toString(),
            tempHeight.toString(),
            propertyName,
          );
        }
      } else {
        if (operation === 'lessthan') {
          if (tempWidth < width || tempHeight < height) {
            if (propertyName === 'featuredImageUrl') {
              this.featuredImageerrorText = 'Image.Upload.ErrorImageDimensions';
            } else if (propertyName === 'iconImageUrl') {
              this.errorText = 'Image.Upload.ErrorImageDimensions';
            }
          } else {
            if (propertyName === 'featuredImageUrl') {
              this.isSaveButtonDisabled = true;
              this.featuredImageUrl = this.loadingImage;
              this.croppedFeaturedImageIconUrl = this.loadingImage;
              this.UploadFileImage(
                '/FileUpload/UploadFeaturedImageDuringEditJson',
                file,
                '1600',
                this.moduleName === this.EventWebPage ? '700': '1000',
                propertyName,
              );
            } else if (propertyName === 'iconImageUrl') {
              this.imageIconUrl = this.loadingImage;
              this.croppedimageIconUrl = this.loadingImage;
              this.UploadFileImage(
                '/FileUpload/UploadMediaDuringEditJson',
                file,
                this.IconImageSizeWidth.toString(),
                this.IconImageSizeHeight.toString(),
                propertyName,
              );
            }
          }
        }
      }
    };
  }
  private UploadFileImage(
    url: string,
    file: any,
    width: string,
    height: string,
    propertyName: string,
  ) {
    this.GetMediaPrefix();
    const formData = new FormData();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    formData.append('file', file);
    formData.append('thumbnailWidth', width);
    formData.append('thumbnailHeight', height);
    formData.append('mediaPrefix',this.mediaPrefix);
    const options = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    this.$axios
      .post(url, formData, options)
      .then((response) => {
        if (propertyName === 'iconImageUrl') {
          this.imageIconUrl = response.data.fullUri;
          this.croppedimageIconUrl = response.data.thumbUri;
          this.$emit(
            'SetImageIconUrl',
            response.data.thumbUri,
            response.data.fullUri,
          );
        } else {
          this.$emit(
            'SetFeaturedImageIconUrl',
            response.data.fullUri,
            response.data.fullUri,
            response.data.height,
            response.data.width,
          );
          this.croppedFeaturedImageIconUrl = response.data.fullUri;
          this.featuredImageUrl = response.data.fullUri;
          this.localFeaturedImageWidth = response.data.width;
          this.localFeaturedImageHeight = response.data.height;
          this.isVisibleShowTitleCheckBox = true;
          this.isSaveButtonDisabled = false;
        }
        this.$root.$emit('showcroopedimage', true);
      })
      .catch(() => {
        this.isSaveButtonDisabled = false;
      });
  }

  private clearFeaturedImageIconUrl() {
    this.featuredImageUrl = '';
    this.$emit('selectImageUrl', '');
    this.$emit('SetFeaturedImageIconUrl', '', '');
    this.$emit('ShowTitleBelowFeaturedImage', false);
    this.FeaturedIconfilename = 'Choose file...';
    this.croppedFeaturedImageIconUrl = '';
    this.isDirtyObject = false;
  }
  private ClickIconGenerator(fontAwesomeIcon: FontAwesomeIcon) {
    // to prevent database hit if we don't have selected any font awesome icon
    if (fontAwesomeIcon.Id > 0) {
      this.GenerateIconImage(fontAwesomeIcon);
    }
  }
  private GenerateIconImage(fontAwesomeIcon: FontAwesomeIcon) {
    this.LatestFontAwesomeIcon = fontAwesomeIcon;
    this.imageIconUrl = this.loadingImage;
    this.croppedimageIconUrl = this.loadingImage;
    this.$emit('SetImageIconUrl', this.imageIconUrl, this.imageIconUrl);
    this.$root.$emit('selected-font-awesome-icon', this.LatestFontAwesomeIcon);
    this.IconGeneratorId = fontAwesomeIcon.Id;
    this.GetMediaPrefix();
    this.$axios
      .post('/FileUpload/GenerateIconImage', {
        fontAwesomeIcon,
        applicationId: this.ProgramFontAwesomeViewModel.ApplicationId,
        foregroundColor: this.LatestForegroundcolor,
        backgroundColor: this.LatestBackroundcolor,
        mediaPrefix: this.mediaPrefix,
      })
      .then((response) => {
        this.imageIconUrl = response.data.Uri;
        this.croppedimageIconUrl = response.data.Uri;
        this.$emit('SetImageIconUrl', response.data.Uri, response.data.Uri);
      })
      .catch(() => {
        // Log error if required
      });
  }
  private clickOnSave() {
    if (this.updateColorInDB) {
      this.UpdateIconColors();
    } else {
      this.$root.$emit('disableTabs', false);
      this.$emit('selectBack', false);
    }
    this.$emit(
      'ShowTitleBelowFeaturedImage',
      this.localShowTitleBelowFeaturedImage,
    );
    this.$emit(
      'SetImageIconUrl',
      this.croppedimageIconUrl === '' || this.croppedimageIconUrl === undefined
        ? (this.imageSrc!== this.featureMissingImage ? this.imageSrc : '')
        : this.croppedimageIconUrl,
      this.croppedimageIconUrl === '' || this.croppedimageIconUrl === undefined
        ? (this.imageSrc !== this.missingImage ? this.imageSrc : '')
        : this.croppedimageIconUrl,
      this.localOnlyShowFeatureImage,
    );
    this.$emit('check-is-full-image',this.localIsFullImage);
    setTimeout(() => {
      this.$root.$emit('customCategoryDetailLoadingVod', false);
    }, 500);
  }
  private clickOnBack() {
    if(!this.isDirtyObject && ((this.localIsFullImage && !this.localShowFullImageOption) || this.moduleName === this.EventModule)) {
      this.showVueModalPopUp = true;
    } else {
      this.clickOnSave();
    }
  }
  // set updated icon color to update in application instance
  private UpdateIconColors() {
    this.showLoading = true;
    if (this.applicationborderColor != null) {
      this.loaderBorderColor = this.applicationborderColor;
    }
    this.$axios
      .post<boolean>(
      '/ApplicationInstance/UpdateApplicationInstanceIconColors',
      {
        iconForegroundColor: this.LatestForegroundcolor,
        iconBackgroundColor: this.LatestBackroundcolor,
      },
    )
      .then((response: any) => {
        if (response.data === true) {
          // alert("color updated")
        } else {
          // alert("error occured");
        }
        this.showLoading = false;
        this.$emit('selectBack', false);
        this.$root.$emit('disableTabs', false);
      })
      .catch(() => {
        this.showLoading = false;
        this.$emit('selectBack', false);
        this.$root.$emit('disableTabs', false);

      });
  }
  private GetMediaPrefix() {
    if(this.moduleName !== null && this.moduleName !== undefined && this.SubModuleName !== null && this.SubModuleName !== undefined) {
      this.mediaPrefix = new PrefixMediaUpload().GetPrefix(this.moduleName,this.SubModuleName);
    }
  }
  private mounted() {
    if(this.IsImageIconUrlExists() || this.IsFeaturedImageIconUrlExists()) {
      this.GetMediaPrefix();
    }
    this.localIsFullImage = this.IsFullImage !== undefined ? this.IsFullImage : false;
    this.localShowFullImageOption = this.ShowFullImageOption !== undefined ? this.ShowFullImageOption : false;
    // this.localImageSrc = this.imageSrc!;
    this.isSaveButtonDisabled = false;
    this.localShowTitleBelowFeaturedImage = this.showTitleBelowFeaturedImage!;
    this.localFeaturedImageWidth = this.featuredImageWidth!;
    this.localFeaturedImageHeight = this.featuredImageHeight!;
    this.croppedFeaturedImageIconUrl = this.featuredimageSrc!;
    if (this.localOnlyShowFeatureImage) {
      this.featuredImageUrl = this.featuredimageSrc!;
    }
    this.localOnlyShowFeatureImage = this.OnlyShowFeatureImage!;
    if (this.ShowFeaturedImage) {
      if (this.HasFeaturedImage) {
        this.optionFeatureImagedefaultColorSelected = 'false';
      } else {
        this.optionFeatureImagedefaultColorSelected = 'true';
      }
    } else if (this.isBulkOperation && !this.ShowFeaturedImage) {
      this.selectedBatchImageRadiobtn = this.EditIcon;
    }
    this.$axios
      .post<FontAwesomeViewModel>('/FontAwesome/GetListAwesomeIcons')
      .then((response) => {
        let foregroundColor = response.data.SelectedForegroundColor;
        let backgroundColor = response.data.SelectedBackgroundColor;
        if (foregroundColor === null) {
          foregroundColor = this.defaultForegroundColor;
        }
        if (backgroundColor === null) {
          backgroundColor = this.defaultBackgroundColor;
        }
        this.ProgramFontAwesomeViewModel = new FontAwesomeViewModel(
          response.data.FontAwesomeIcons,
          backgroundColor,
          foregroundColor,
          response.data.ApplicationId,
        );
        this.LatestFontAwesomeIcon = this.GetLatestFontAwesomeIcon()!;
        this.LatestForegroundcolor = foregroundColor;
        this.LatestBackroundcolor = backgroundColor;
      })
      .catch(() => {
        // Log error if required
      });
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    window.scrollTo(0, 0);

    // Set old backup values
    this.oldImageSrc = this.imageSrc!;
    this.oldImageFullSrc = this.imageFullSrc!;
    this.oldFeaturedimageSrc = this.featuredimageSrc!;
    this.oldFeaturedImageFullSrc = this.featuredImageFullSrc!;
    this.oldApplicationborderColor = this.applicationborderColor!;
    this.oldFeaturedImageWidth = this.featuredImageWidth!;
    this.oldFeaturedImageHeight = this.featuredImageHeight!;
    this.oldLeftPosition = this.leftPosition!;
    this.oldTopPosition = this.topPosition!;
    this.oldCroppedFeaturedImageIconUrl = this.featuredimageSrc!;
    this.$root.$emit('disableTabs', true);

    if (this.moduleName === this.EventModule || this.localIsFullImage) {
      this.featuredImageSizeWidth = this.featuredImageWidth!;
      this.featuredImageSizeHeight = this.featuredImageHeight!;
    }
    this.unsavedModalPopUp = new VueModalPopUpViewModel(
      i18n.t('UnsavedChanges').toString(),
      i18n.t('EventSetting.UnsavedItems').toString(),
      true,
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('@/assets/Images/Icon-warning.svg'),
      i18n.t('CancelButton').toString(),
      i18n.t('SaveAndSwitch').toString(),
    );
  }
  private GetLatestFontAwesomeIcon() {
    const extractedString = this.imageSrc!.substring(this.imageSrc!.lastIndexOf(('/'+this.mediaPrefix)) + (this.mediaPrefix.length + 1));
    const favIconName = extractedString.substring(0,extractedString.indexOf('_'));
    return extractedString.indexOf('_') > 0 ? this.ProgramFontAwesomeViewModel.FontAwesomeIcons.find((item) => item.IconName.includes(favIconName)) : FontAwesomeIcon.createEmpty();
  }
  // anything change in model
  private IsObjectDirty() {
    if (this.moduleName === this.EventModule) {
      const objectState = this.CompareValuesForDirtyCheck();
      if (!objectState) {
        this.isDirtyObject = false;
      }
    }
  }
  private GetUploadLogoWarning() {
    return this.$t('ImageProcess.CustomThemeLogoDetail',{size: DialogueThemesSettings.DefaultLogoSize,height: DialogueThemesSettings.DefaultLogoHeight});
  }
  // compare values
  private CompareValuesForDirtyCheck(): boolean {
    const result =
      this.oldImageSrc === this.imageSrc &&
      this.oldImageFullSrc === this.imageFullSrc &&
      this.oldFeaturedimageSrc === this.featuredimageSrc &&
      this.oldFeaturedImageFullSrc === this.featuredImageFullSrc &&
      this.oldApplicationborderColor === this.applicationborderColor &&
      this.oldFeaturedImageWidth === this.featuredImageWidth &&
      this.oldFeaturedImageHeight === this.featuredImageHeight &&
      this.oldLeftPosition === this.leftPosition &&
      this.oldTopPosition === this.topPosition &&
      this.oldCroppedFeaturedImageIconUrl === this.featuredimageSrc;
    return result;
  }
  private Discard() {
    if (!this.ShowFeaturedImage) {
      this.croppedimageIconUrl = this.oldImageSrc;
      // this.imageFullSrc = this.oldImageFullSrc;
      this.imageIconUrl = this.oldImageSrc!;
      this.featuredimageSrc = this.oldFeaturedimageSrc;
      this.featuredImageFullSrc = this.oldFeaturedImageFullSrc;
      this.applicationborderColor = this.oldApplicationborderColor;
      this.featuredImageWidth = this.oldFeaturedImageWidth;
      this.featuredImageHeight = this.oldFeaturedImageHeight;
      this.leftPosition = this.oldLeftPosition;
      this.topPosition = this.oldTopPosition;
      this.isDirtyObject = true;
      this.Reset();
      this.$emit('SetImageIconUrl', '', '');
      this.$emit('selectImageUrl', this.oldImageSrc);
      this.Iconfilename = 'Choose file...';
      this.$emit('Discard');
    } else {
      // TODO
      this.featuredImageUrl = this.oldFeaturedimageSrc;
      // this.featuredimageSrc = this.oldFeaturedimageSrc;
      this.isDirtyObject = true;
      this.featuredImageFullSrc = this.oldFeaturedImageFullSrc;
      this.featuredImageWidth = this.oldFeaturedImageWidth;
      this.featuredImageHeight = this.oldFeaturedImageHeight;
      this.croppedFeaturedImageIconUrl = this.oldCroppedFeaturedImageIconUrl;
      this.$emit('selectImageUrl', this.croppedFeaturedImageIconUrl);
      this.$emit('SetFeaturedImageIconUrl', '', '');
      this.$emit('ShowTitleBelowFeaturedImage', false);
      this.FeaturedIconfilename = 'Choose file...';
      this.$emit('DiscardFeature');
    }
  }
  private Reset() {
    const el = this.$refs.file as any;
    if (el) {
      el.value = null;
    }
    const elFeature = this.$refs.file1 as any;
    if (elFeature) {
      elFeature.value = null;
    }
    this.Iconfilename = i18n
      .t('VirtualMeetings.AddVideo.ChooseFile')
      .toString();
    this.FeaturedIconfilename = i18n
      .t('VirtualMeetings.AddVideo.ChooseFile')
      .toString();
  }
  // close vue modal popup
  private CloseModelPopup() {
    this.showVueModalPopUp = false;
  }
  // save and switch modal popup
  private SaveAndSwitchTab() {
    this.isDirtyObject = true;
    this.clickOnBack();
    this.showVueModalPopUp = false;
  }
}
