































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Chrome } from 'vue-color';
import vClickOutside from 'v-click-outside';
@Component({
  components: {
    Chrome,
    vClickOutside,
  },
})
/* eslint-disable no-unused-expressions */
export default class Loading extends Vue {
  @Prop()
  private color?: string;
  private colors: any = {};
  private colorValue = '';
  private displayPicker = false;
  private created(){
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    Vue.use(vClickOutside);
  }
  private mounted() {
    this.setColor(this.color || '#000000');
  }
  private setColor(color: string) {
    this.updateColors(color);
    this.colorValue = color;
  }
  private updateColors(color: string) {
    if (color) {
      if (color.slice(0, 1) === '#') {
        this.colors = {
          hex: color,
        };
      }
    }
  }
  private showPicker() {
    this.displayPicker = true;
  }
  private closePicker() {
    if ( this.displayPicker === true) {
      this.hidePicker();
    }
  }
  private hidePicker() {
    this.displayPicker = false;
  }
  private togglePicker() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.displayPicker ? this.hidePicker() : this.showPicker();
  }

  private updateFromInput() {
    this.updateColors(this.colorValue);
  }
  private updateFromPicker(color: any) {
    // this.hidePicker();
    this.colors = color;
    this.colorValue = color.hex;
  }
  private GetColorCss(c: string) {
    const ele = document.createElement('div');
    ele.style.color = c;
    return ele.style.color
      .split(/\s+/)
      .join('')
      .toLowerCase();
  }
  private IsColorValid(c: string) {
    const s = this.GetColorCss(c);
    return s ? true : false;
  }
  @Watch('colorValue')
  private onPropertyChange(val: string, oldVal: string) {
    if (val && this.IsColorValid(val)) {
      this.updateColors(val);
      this.$emit('input', val);
      if (this.$attrs.id === 'Foregroundcolor') {
        this.$emit('SetForegroundcolorFromPicker', val, oldVal);
      } else if (this.$attrs.id === 'Backgroundcolor') {
        this.$emit('SetBackgroundcolorFromPicker', val, oldVal);
      }
    }
  }
  @Watch('color')
  private oncolorPropertyChange(val: string) {
    this.setColor(val);
    this.updateColors(val);
    this.$emit('input', val);
  }
}
